<app-navbar-account></app-navbar-account>
<div class="container-fluid full dark">
    <div class="row p-2">
        <div class="col-sm-12 text-center mb-4 mt-2">
            <h5 class="h4 text-white">Realice peticiones al Equipo de Planeta Relax</h5>

        </div>
        <div class="col-sm-6 mt-2">
           <h5 class="text-white">Escriba su peticion</h5>
           <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label text-white">Escriba la peticion que desea hacerle al equipo de planeta relax </label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="10" [(ngModel)]="mensaje"></textarea>
          </div>
          <div class="d-grid gap-2 col-6 mx-auto">
            <button class="btn btn-primary" type="button" [disabled]="!mensaje" (click)="sendpeticion()">Enviar</button>          
          </div>


        </div>
        <div class="col-sm-6 mt-2">
           <h5 class="text-white">Peticiones Realizadas</h5>
           <div *ngFor="let item of data">
             <app-peticion [data]="item"></app-peticion>
           </div>   
        </div>

    </div>

</div>

