<app-navbar-account></app-navbar-account>
<div class="container-fluid dark fondo">
  <div class="row">
    <div class="col-sm-12">
      <h3 class="text-center text-white mt-2">Bienvenida</h3>
    </div>

    <!-- <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="card bg-dark">
        <div class="card-header bg-dark">
          <h5 class="h5 text-white text-center">Publicaciones Vigentes</h5>
        </div>
        <div class="card-body relax-gradiente">
          <table class="table text-white table-sm table-responsive">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Tipo</th>
                <th scope="col">Dias Restantes</th>
                <th scope="col">Fecha de Vencimiento</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Mark</td>
                <td>Otto</td>
                <td>@mdo</td>
                <td><button type="button" class="btn btn-light btn-sm">Renovar</button></td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Jacob</td>
                <td>Thornton</td>
                <td>@fat</td>
                <td><button type="button" class="btn btn-light btn-sm">Renovar</button></td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td colspan="2">Larry the Bird</td>
                <td>@twitter</td>
                <td><button type="button" class="btn btn-light btn-sm">Renovar</button></td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
            <div class="btn-group" role="group" aria-label="Basic mixed styles example">
              <button type="button" class="btn btn-danger">Mis Compras</button>
              <button type="button" class="btn btn-success">Mis pagos</button>
              <button type="button" class="btn btn-warning">Mis Finanzas</button>
            </div>
          </div>

        </div>

      </div>

    </div> -->
    <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="card bg-dark">
        <div class="card-header bg-dark">
          <h5 class="h5 text-white text-center">Ultimas Noticias</h5>
        </div>
        <div class="card-body relax-gradiente">
          <div *ngFor="let item of noticias">
            <app-news [data]="item"></app-news>
          </div>

        </div>

      </div>
      <div class="card-header">
        <h5 class="h5 text-white text-center"></h5>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="card bg-dark">
        <div class="card-header bg-dark">
          <h3 class="h5 text-white text-center">Acciones Rapidas</h3>
        </div>
        <div class="card-body relax-gradiente">
          <div class="d-flex flex-column">
            <h5 class="text-white text-center">Subirte - Bajarte del Sitio</h5>
            <div class="bg-dark p-2 text-center">
              <button class="btn btn-outline-success mt-2" (click)="setStates({'value':false,'x':'visible'})" *ngIf="profile.visible">Eres Visible en el sitio ON</button>
              <button class="btn btn-outline-danger  mt-2" (click)="setStates({'value':true,'x':'visible'})" *ngIf="!profile.visible">Te Bajaste! No estas visible OFF</button>
            </div>
            <div class="text-center mt-3">
              <div class="bg-dark p-2">
                <h5 class="text-white text-center">Disponible Ahora</h5>
                <div class="text-center mb-3" *ngIf="visible">
                  <div class="d-flex rounded p-2 flex-row justify-content-around relax-gradiente text-white">
                    <div class="d-flex flex-column">
                      <div class="h5">{{hours}}</div>
                      <div>HH</div>
                    </div>
                    <div class="d-flex flex-column">
                      <div class="h5"> {{minutes}}</div>
                      <div>MM</div>
                    </div>
                    <div class="d-flex flex-column">
                      <div class="h5">{{seconds}}</div>
                      <div>SS</div>
                    </div>
                  </div>

                </div>
                <h5 class="text-white">Coloca tiempo online</h5>
                <div class="btn-group mb-3" role="group" aria-label="Basic example">
                  <button type="button" class="btn btn-primary" (click)="makeOnline(1)">1 hora</button>
                  <button type="button" class="btn btn-primary" (click)="makeOnline(2)">2 horas</button>
                  <button type="button" class="btn btn-primary" (click)="makeOnline(3)">3 horas</button>
                  <button type="button" class="btn btn-primary" (click)="makeOnline(4)">4 horas</button>
                </div>
                <div class="mt-5 justify-content-center">
                  <button class="btn btn-danger  mt-2" (click)="setStates({'value':false,'x':'online'})" *ngIf="profile.online">APAGAME YA !! </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12">
      <div class="card bg-dark">
        <div class="card-header bg-dark">
          <h5 class="h5 text-white text-center">Mis Publicaciones</h5>
        </div>
        <div class="card-body relax-gradiente">
          <table class="table  table-striped table-dark table-sm text-center">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Tipo de Publicacion</th>
                <th scope="col">Estado</th>
                <th scope="col">Dias Restantes</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="finanzas">
                <th scope="row">1</th>
                <td>Ficha General</td>
                <td *ngIf="finanzas.active" class="text-success">ACTIVA</td>
                <td *ngIf="!finanzas.active" class="text-danger">INACTIVA</td>
                <td>{{finanzas.dias}}</td>
              </tr>         
            </tbody>
          </table>

        </div>
      </div>
      <div class="card-header">
        <h5 class="h5 text-white text-center"></h5>
      </div>
    </div>


  </div>

</div>



<app-modal-login [show]="loading"></app-modal-login>
