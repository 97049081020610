<div class="container-fluid">
  <div class="row bg-dark">
    <div class="col-sm-12">
        <h4 class="text-white text-center">Todas las Imagenes </h4>
    </div>
    <div class="col-sm-12 m-0 p-0">
        <div class="d-flex flex-wrap justify-content-evenly">
            <div *ngFor="let item of data" >
                <div class="card text-white m-2  relax-gradiente rounded p-1">
                    <div class="text-right" style="padding: 5px;z-index: 150; ">
                        <button class="btn btn-danger btn-circle btn-sm" (click)="confirmation(item)">
                            <i class="fas fa-trash"></i>
                        </button>              
                    </div>
                    <div (click)="open(item)" style="margin-top: -40px;z-index: 100;cursor: zoom-in; " >
                        <app-image-container img="{{item.url}}" tipe="{{item.tipo}}"  ></app-image-container>
                    </div>              
                  </div>        
            </div>
        </div>
    </div>
  </div>
</div>

<div *ngIf="show_confirmation" class="modal-background">
    <div class="modal fade show" style="display: block;" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Estas Seguro ?</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancel()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Estas seguro de eliminar esta foto?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="cancel()">Cancel</button>
              <button type="button" class="btn btn-primary" (click)="quitarImagen()">Eliminar</button>
            </div>
          </div>
        </div>
      </div>
</div>

<app-modal-login [show]="modal_loading"></app-modal-login>

<app-modal-image [image]="imageUrl"  [show]="show"></app-modal-image>

