<nav class="navbar navbar-expand-lg navbar-dark bg-dark m-0 p-0">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="['/chicas/rosario']" routerLinkActive="router-link-active" >
        <img src="/assets/img/logo.png" alt="" width="160" height="64">        
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0" >
          <li class="nav-item" *ngFor="let item of data">
            <a class="nav-link " aria-current="page" (click)="nav(item.route)" >{{item.name}}</a>
          </li>     
         
        </ul>
   
      </div>
    </div>
  </nav>
