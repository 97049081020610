<app-navbar></app-navbar>
<div class="container-fluid dark p-0">
  <div class="input-group input-group-sm mb-2 pl-4 w-100">
    <span class="input-group-text bg-dark border-0" id="basic-addon1">
      <i class="fas fa-search icon"></i>
    </span>
    <input type="text" (keyup)="appyfilter($event.target.value)"  class="form-control bg-dark" placeholder="Busca a tu preferida" aria-label="Username" aria-describedby="basic-addon1">
  </div>
  <div class="row dark full p-0 m-0">
    <div class="col-sm-12 p-0">
      <div *ngIf="loading" class="text-center">
        <app-logo></app-logo>
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <h1 class="text-center text-white display-1 mt-2">Cargando ...</h1>
      </div>
      <div class="d-flex flex-row flex-wrap justify-content-center align-items-start" *ngIf="!loading">
        <div *ngFor="let item of data" >
          <app-ficha [heigth]="cardHeight" [width]="cardWidth" [item]="item" ></app-ficha>
        </div>
        <div *ngIf="data.length <= 0">
          <app-logo></app-logo>
          <div>
            <h1 class="text-center text-white display-1">Ups...No se encontraron resultados</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
