<div class="modal-background" *ngIf="show">
    <!-- Logout Modal-->
    <div class="modal fade show bg-dark" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true" style="display: block;">
      <div class="modal-dialog" role="document">
        <div class="modal-content p-4">
          <div class="d-flex align-items-center">
            <strong>Loading...</strong>
            <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
          </div>         
        </div>
      </div>
    </div>
</div>

