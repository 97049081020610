<div *ngIf="!show">
  <app-navbar></app-navbar >
</div>
<div class="container-fluid dark" *ngIf="!show">
  <div class="row full p-4">
    <div class="col-lg-12 col-md-12 col-sm-12 text-center p-0 m-0">
      <div class="d-flex flex-row justify-content-center">
        <img [src]="profileimage" alt="" class="foto">
        <img [src]="agua" alt="" class="box-img2">
        <img [src]="gif" alt="" class="gif" *ngIf="colletion.online">
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="row">
        <div class="col-sm-12 text-center mt-2">
          <h3 class="type h3 lh-lg"> {{item.username}}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6" *ngIf="item.telefono">
          <div class="d-grid gap-2">
            <a type="button" class="btn btn-info boton_llamada" [href]="telefono" target="_blank" ><img style="width: 20px;"
                src="/assets/svg/llamar.svg" alt="llama a las chicas rosario"> Llámala</a>
          </div>
        </div>
        <div class="col-sm-6" *ngIf="item.whatsapp">
          <div class="d-grid gap-2">
            <a type="button" class="btn boton_whatsapp" [href]="whatsapp" target="_blank" ><img style="width: 20px;"
                src="/assets/svg/whatsapp.svg" alt="llama a las chicas rosario"> Chatea</a>
          </div>
        </div>
        <div class="col-sm-6 mt-4 text-white">
          <h5>Mi horario</h5>
          <p class="p-0 m-0"><strong>Trabajo los días:</strong> {{item.inicio}} hasta el {{item.fin}} </p>
          <p class="p-0 m-0"><strong>Mi Horario:</strong> {{item.horario_inicio}}  {{item.horario_fin}} </p>
        </div>
        <div class="col-sm-6 mt-4 text-white">
          <h5>Mi Zona</h5>
          <p class="p-0 m-0"><strong>Sector:</strong> {{item.zonas}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 text-white mensaje p-2">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <div class="d-grid gap-2">
                  <button class="btn fondo_neon btn-danger" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Más Sobre Mi
                  </button>

                </div>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <p class="p-0 m-0"><strong>Edad:</strong> {{item.edad}}</p>
                  <p class="p-0 m-0"><strong>¿Fuma?:</strong> {{item.fumadora}}</p>
                  <p class="p-0 m-0"><strong>Peso:</strong> {{item.peso}}</p>
                  <p class="p-0 m-0"><strong>Medidas:</strong> {{item.busto}} - {{item.cintura}} - {{item.cadera}}</p>
                  <p class="p-0 m-0"><strong>Servicios:</strong> {{item.servicios}}</p>           
                 
                  <p class="p-0 m-0"><strong>Atencion:</strong>
                    <li *ngFor="let x of item.atencion">{{x}}</li>
                  </p>                  
                </div>
              </div>
            </div>     
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 mb-2">
      
      <h5 class="text-center text-white">Mis Fotos</h5>
    </div>
    <div class="col-sm-12 p-0 m-0 fondo_neon_dos rounded">   

      <app-gallery [slides]="colletion.imagenes" [telefono]="telefono" [whatsapp]="whatsapp"></app-gallery>
    </div>
  </div>
</div>

<div style="max-height: 100vh;overflow-y: hidden;">
  <app-imagen [show]="show"  [slides]="colletion.imagenes" *ngIf="show"  [telefono]="telefono" [whatsapp]="whatsapp"></app-imagen>
</div>

<!-- <div class="btn-group" [ngStyle]="{'top.px': (screenHeight-38),'position': 'fixed'}" style="width: 100%;">
  <a href="#" class="btn btn-primary" aria-current="page">Fotos</a>
  <a href="#" class="btn btn-primary">Videos</a>
  <a href="#" class="btn btn-primary">Llamala</a>
  <a href="#" class="btn btn-primary">Chatea</a>
</div> -->
