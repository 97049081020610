<div class="container-fluid dark height-100">
  <div class="row">
    <div class="col-sm-12">
      <app-logo></app-logo>
    </div>
  </div>
  <div class="row vh-100">
    <app-modal-login [show]="loading"></app-modal-login>
  </div>
</div>
