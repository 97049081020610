<nav class="navbar navbar-expand-lg navbar-dark bg-dark m-0 p-0">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="['/anunciantes/all']" routerLinkActive="router-link-active" >
        <img src="/assets/img/logo.png" alt="" width="120" height="50">        
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0" >
          <li class="nav-item" *ngFor="let item of data">
            <a class="nav-link " aria-current="page" (click)="show(item.id)" >{{item.titulo}}</a>
          </li>     
         
        </ul>
   
      </div>
    </div>
</nav>

<div class="container-fluid dark fondo pt-2">
    <!-- <div class="row">
        <div class="col-sm-12 text-center p-2">
            <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" class="btn btn-primary" *ngFor="let item of data" (click)="show(item.id)">{{item.titulo}}</button>
               
              </div>
        </div>      
    </div> -->
    <div class="row" *ngIf="screen=='1'">
        <div class="col-sm-12">
            <app-allfotos [id]="id"></app-allfotos>
        </div>
    </div>
    <div class="row" *ngIf="screen=='2'">
        <div class="col-sm-12">
            <app-subirfotos [id]="id"></app-subirfotos>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12" *ngIf="screen=='3'">
            <app-fotoslistado [id]="id"></app-fotoslistado>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12" *ngIf="screen=='4'">
            <app-fotoperfil [id]="id"></app-fotoperfil>
        </div>
    </div>


</div>


