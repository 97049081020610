<div class="dark container-fluid">
  <div class="row vh-100">
    <div class="col-sm-12">
      <div class="d-flex h-100 flex-column justify-content-between align-items-center">
        <div itemscope itemtype="" class="pt-3 type">
          <h1>Encuentra las mejores Chicas de Rosario</h1>
        </div>
        <div class="logo_fill">
        </div>

        <button type="button" class="btn btn-danger boton_intro" [routerLink]="['/chicas/rosario']">Entrá</button>

        <div class="mensaje pl-3 pr-3" style="max-width: 20rem">
          Al entrar, doy por aceptado que SOY MAYOR DE EDAD y que ACEPTO los <a href="#">términos y condiciones de
            Planeta Relax</a>
        </div>
        <div class="pb-5 mb-4">
          <button type="button" class="btn btn-info boton_cuenta" [routerLink]="['/login']"
            routerLinkActive="router-link-active">Acceder a mi cuenta</button>
        </div>
      </div>
    </div>
  </div>

</div>
