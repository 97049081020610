<div class="modal fade show " id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true" style="display: block;cursor: zoom-out;" *ngIf="show">
  <div class="modal-dialog  modal-xl" role="document">
    <div class="modal-content p-4 relax-gradiente" (click)="close()"  style="cursor: zoom-out;">
      <div [ngStyle]="{'background-image': 'url('+ image +')'}" class="img-big">
      </div>  
    </div>
  </div>
</div>

