<!-- Modal -->
<div class="modal-background" *ngIf="show" style="display:block;">
  <div class="modal fade show" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content relax-gradiente">   
        <div class="modal-body">
          <div class="modal-body m-0 p-2">
            <div *ngIf="showWebcam" class="d-flex justify-content-center">
              <webcam [height]="screenHeight" [width]="screenWidth" [trigger]="triggerObservable"
                (imageCapture)="handleImage($event)" [allowCameraSwitch]="allowCameraSwitch"
                [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" [imageQuality]="1"
                (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
              </webcam>          
            </div>
            <div class="d-flex align-items-center justify-content-center" *ngIf="loading">
              <strong class="text-white">Procesando Imagen...</strong>
              <div class="spinner-border text-light ml-auto" role="status" aria-hidden="true"></div>
            </div>  
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true" style="display:block;">
    <div class="modal-dialog text-center">
      <div class="modal-content relax-gradiente">
        <div class="modal-body m-0 p-2">
          <div *ngIf="showWebcam" class="d-flex justify-content-center">
            <webcam [height]="screenHeight" [width]="screenWidth" [trigger]="triggerObservable"
              (imageCapture)="handleImage($event)" [allowCameraSwitch]="allowCameraSwitch"
              [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" [imageQuality]="1"
              (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
            </webcam>          
          </div>
          <div class="d-flex align-items-center justify-content-center" *ngIf="loading">
            <strong class="text-white">Procesando Imagen...</strong>
            <div class="spinner-border text-light ml-auto" role="status" aria-hidden="true"></div>
          </div>    
        </div>
      </div>
    </div>
  </div> -->
</div>


